import { Header, MainContainer, ContentContainer, SidebarContainer, BodyContainer, Content } from "./styles";
import { FC, PropsWithChildren, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SideBar from "../../components/sideBar/SideBar";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom"

const MainLayout:FC<PropsWithChildren> = ({ children }) => {
  return(
    <MainContainer>
      <ContentContainer>
        <SidebarContainer>
          <SideBar/>
        </SidebarContainer>
        <BodyContainer>
          <Header></Header>
          <Content>
            <Outlet/>
          </Content>
        </BodyContainer>
      </ContentContainer>
    </MainContainer>
  )
}

export default MainLayout;