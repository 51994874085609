import { FC, SyntheticEvent } from "react";
import { SideBarItemContainer } from "./styles";
import { SideBarDataItem } from "./SideBar";
import SideBarAccordion from "./SideBarAccordion";
import { Link } from "react-router-dom";

interface Props extends SideBarDataItem {
  expanded: boolean,
  handleChange: (id: string) => (event: SyntheticEvent, newExpanded: boolean) => void
}

const SideBarItem: FC<Props> = ({
  id,
  title,
  Icon,
  accordionData,
  expanded,
  route,
  handleChange,
}) => {

  if (accordionData) return (
    <SideBarAccordion title={title} Icon={Icon} expanded={expanded} onChange={handleChange(id)}>
      {accordionData.map(item => (
        <Link to={item.route}>
          <SideBarItemContainer>
            <span>{item.title}</span>
          </SideBarItemContainer>
        </Link>
      ))}
    </SideBarAccordion>
  )

  return (
    <Link to={route || "/"}>
      <SideBarItemContainer>
        <Icon /> <span style={{ marginLeft: "14px" }}>{title}</span>
      </SideBarItemContainer>
    </Link>
  )
}

export default SideBarItem