import MainLayout from "../../layouts/mainLayout/MainLayout";
import { Box } from "@mui/material";

const Dashboard = () => {
  return (
    <Box>

      Dashboard
    </Box>
  )
}

export default Dashboard