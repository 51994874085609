import { Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from '@mui/icons-material/BarChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import { FC, SyntheticEvent, useState } from "react";
import SideBarItem from "./SideBarItem";

export type SideBarDataItem = {
  id: string,
  title: string,
  route?: string,
  Icon: FC,
  accordionData?: {
    title: string,
    route: string
  }[]
}

type SideBarData = SideBarDataItem[]

const sideBarData: SideBarData = [
  {
    id: "main",
    title: "Главная страница",
    route: "/",
    Icon: HomeIcon,
  },
  {
    id: "statistic",
    title: "Статистика",
    Icon: BarChartIcon,
    accordionData: [
      {
        title: "Список всех устройств",
        route: "/statistic/device"
      },
      {
        title: "Список всех лекал",
        route: "/statistic/pattern"
      },
      {
        title: "Статистика резов",
        route: "/"
      },
      {
        title: "Протоколы",
        route: "/"
      },
    ]
  },
  {
    id: "admin",
    title: "Администрирование системы",
    Icon: AdminPanelSettingsIcon,
    accordionData: [
      {
        title: "Управление пользователями",
        route: "/"
      },
    ]
  },
  {
    id: "settings",
    title: "Настройки устройств",
    Icon: SettingsIcon,
    accordionData: [
      {
        title: "Устройства",
        route: "/"
      },
      {
        title: "Удалённый доступ к устройству",
        route: "/"
      },
      {
        title: "Добавить новое",
        route: "/"
      },
      {
        title: "ПО плоттеров",
        route: "/"
      },
      {
        title: "Разрешения",
        route: "/"
      },
    ]
  }
]

const SideBar: FC = () => {
  const [expanded, setExpanded] = useState<string>("");

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : "");
    };

  return (
    <Box width="280px" height="100%">
      {sideBarData.map((item => <SideBarItem expanded={expanded === item.id}
                                             handleChange={handleChange} {...item} />))}
    </Box>
  )
}

export default SideBar
