import { AccordionProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Accordion, AccordionDetails, AccordionSummary, SideBarItemContainer } from "./styles";

interface Props extends AccordionProps {
  title: string,
  Icon: FC,
}

const SideBarAccordion:FC<PropsWithChildren<Props>> = ({
  title,
  Icon,
  children,
  ...props
}) => {

  return (
    <Accordion {...props} sx={{bgcolor: "inherit"}}>
      <AccordionSummary>
        <SideBarItemContainer>
          <Icon/> <span style={{marginLeft: "14px"}}>{title}</span>
        </SideBarItemContainer>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default SideBarAccordion